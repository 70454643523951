import React, { useEffect, useContext, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { useLocation } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";

// My Files
import { DatabaseContext, UserContext } from "../../context";
import ToolbarWithLogo from "../ToolbarWithLogo";
import firebase from "../../firebase";
import { stringToBool } from "../../utility";
import SurveyResultsTable from "./Survey Components/SurveyResultsTable";
// import SurveyResultsFeed from "./Survey Components/SurveyResultsFeed";
import BackButton from "../BackButton";

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        padding: 15,
        paddingBottom: 75,
    },
    paper: {
        padding: 0,
        // backgroundColor: "purple",
    },
    dividerDiv: {
        height: 15,
        width: "100%",
    },
    divider: {
        marginTop: 20,
        marginBottom: 20,
        width: "100%",
    },
    tableContainer: {
        marginTop: 30,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 10,
        maxHeight: window.innerHeight - 255,
        // width: window.innerWidth,
        overflow: "scroll",
    },
    tableHead: {
        background: theme.palette.primary.main,
    },
    tableHeaderCell: {
        color: "white",
        borderRight: "1px solid rgb(200,200,200)",
    },
    downloadButton: {
        position: "absolute",
        left: "auto",
        right: 10,
        top: 75,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SurveyResultsPage(props) {
    const classes = useStyles();
    const { currentUserData } = useContext(UserContext);
    const location = useLocation();
    const history = useHistory();

    const { surveyInfo, users, groups } = useContext(DatabaseContext);

    // const [key, setKey] = useState("");

    const [survey, setSurvey] = useState();
    const [responses, setResponses] = useState();
    const [rows, setRows] = useState();
    const [columns, setColumns] = useState([]); // These contain the questions

    const [statusIsVisible, setStatusIsVisible] = useState(false);
    const [statusType, setStatusType] = useState("error");
    const [statusMessage, setStatusMessage] = useState("");
    const [showRequiredText, setShowRequiredText] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);

    const [anonymous, setAnonymous] = useState(true);

    const getSurvey = useCallback(
        async (key) => {
            let surveyRef = firebase.database().ref("surveys").child(key);

            if (!survey) {
                console.log("Reading Survey from Firebase");

                surveyRef.on("value", function (snapshot) {
                    let key = snapshot.key;
                    let data = snapshot.val();

                    let questionsArray = [];

                    if (data && data.questions) {
                        let questionKeys = Object.keys(data.questions);
                        let questionValues = Object.values(data.questions);

                        for (let i = 0; i < questionKeys.length; i++) {
                            var optionsArray = [];

                            let questionObject = {
                                key: questionKeys[i],
                                row: questionValues[i].row,
                                questionText: questionValues[i].questionText,
                                answerFormat: questionValues[i].answerFormat,
                                answerRequired: stringToBool(
                                    questionValues[i].answerRequired
                                ),
                                fileType: questionValues[i].fileType,
                                linkKey: questionValues[i].linkKey,
                            };

                            // Get the multiple choice options if present
                            if (questionValues[i].multipleChoiceOptions) {
                                let optionKeys = Object.keys(
                                    questionValues[i].multipleChoiceOptions
                                );
                                let optionValues = Object.values(
                                    questionValues[i].multipleChoiceOptions
                                );

                                for (let j = 0; j < optionKeys.length; j++) {
                                    let optionObject = {
                                        key: optionKeys[j],
                                        index: optionValues[j].index,
                                        text: optionValues[j].text,
                                    };

                                    optionsArray.push(optionObject);
                                }
                            }

                            questionObject.multipleChoiceOptions = optionsArray;
                            questionsArray.push(questionObject);
                        }
                    }

                    let survey = {
                        key: key,
                        questions: questionsArray.sort((a, b) =>
                            a.row > b.row ? 1 : -1
                        ),
                    };

                    // console.log(survey);
                    setSurvey(survey);
                });
            } else {
                console.log("Already Read Survey");
            }
        },
        [survey]
    );

    const getResponses = useCallback(
        async (surveyKey) => {
            let responsesRef = firebase.database().ref("surveyresponses/");

            if (!responses) {
                console.log("Reading Reponses from Firebase");

                responsesRef.on("value", function (snapshot) {
                    var responsesArray = [];

                    snapshot.forEach(function (childSnapshot) {
                        let key = childSnapshot.key;
                        let data = childSnapshot.val();

                        let answersArray = [];

                        if (data.survey === surveyKey) {
                            if (data && data.answers) {
                                let answerKeys = Object.keys(data.answers);
                                let answerValues = Object.values(data.answers);

                                for (let i = 0; i < answerKeys.length; i++) {
                                    let answerObject = {
                                        key: answerKeys[i],
                                        answer: answerValues[i].answer,
                                        answerFormat:
                                            answerValues[i].answerFormat,
                                        question: answerValues[i].question,
                                        questionText:
                                            answerValues[i].questionText,
                                    };

                                    // Get the multiple choice options if present
                                    // if (questionValues[i].multipleChoiceOptions) {
                                    //     let optionKeys = Object.keys(
                                    //         questionValues[i].multipleChoiceOptions
                                    //     );
                                    //     let optionValues = Object.values(
                                    //         questionValues[i].multipleChoiceOptions
                                    //     );

                                    //     for (let j = 0; j < optionKeys.length; j++) {
                                    //         let optionObject = {
                                    //             key: optionKeys[j],
                                    //             index: optionValues[j].index,
                                    //             text: optionValues[j].text,
                                    //         };

                                    //         optionsArray.push(optionObject);
                                    //     }
                                    // }

                                    answersArray.push(answerObject);
                                }
                            }

                            let response = {
                                key: key,
                                submissionDate: data.submissionDate,
                                user: data.user,
                                survey: data.survey,
                                surveyTitle: data.surveyTitle,
                                answers: answersArray,
                            };

                            responsesArray.push(response);
                            // console.log(response);
                        }
                    });

                    setResponses(responsesArray);
                    prepareRows(responsesArray);
                });
            } else {
                console.log("Already Read Responses");
            }
        },
        [responses]
    );

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);

        const key = urlParams.get("key");

        let matchingInfo = surveyInfo.filter((s) => s.key === key)[0];

        if (matchingInfo && currentUserData) {
            // Double check that the user is allowed to view the result for this survey
            // in case they got to this page direcly using the URL

            let hasAccess = false;
            // Check if user created survey

            if (matchingInfo.createdBy === currentUserData.key) {
                hasAccess = true;
            }

            // Check if user was added to survey individually
            let matchingUser = matchingInfo.users.filter(
                (u) => u.key === currentUserData.key
            )[0];

            if (matchingUser) {
                if (matchingUser.canViewResults) {
                    hasAccess = true;
                }
            }

            setAnonymous(matchingInfo.anonymous);

            // Check if user is in a group that was added to the survey
            matchingInfo.groups.forEach((surveyGroup) => {
                let matchingGroup = groups.filter(
                    (g) => g.key === surveyGroup.key
                )[0];
                if (matchingGroup) {
                    if (
                        matchingGroup.users.filter(
                            (u) => u.key === currentUserData.key
                        ).length > 0
                    ) {
                        if (surveyGroup.canViewResults) {
                            hasAccess = true;
                        } else {
                        }
                    }
                }
            });

            if (hasAccess) {
                getSurvey(key);
                getResponses(key);
            }

            if (survey) {
                console.log("Have read survey succesfully");
            }
        }

        // eslint-disable-next-line
    }, [currentUserData, surveyInfo, survey]);

    function prepareRows(responsesArray) {
        // console.log(responsesArray);
        if (!rows) {
            var rowsArray = [];
            // First need to get all the question texts, for the table headers
            var columnsArray = [];

            responsesArray.forEach((response) => {
                response.answers.forEach((answer) => {
                    let questionText = answer.questionText;

                    if (
                        columnsArray.filter((c) => c.key === answer.question)
                            .length === 0
                    ) {
                        if (!columnsArray.includes(questionText)) {
                            columnsArray.push({
                                key: answer.question,
                                text: questionText,
                            });
                        }
                    }
                });
            });

            // Then go through all the reponses and build the row objects
            responsesArray.forEach((response) => {
                let rowObject = {};
                let rowAnswers = [];

                rowObject.key = response.key;

                let matchingUser = users.filter(
                    (u) => u.key === response.user
                )[0];
                if (matchingUser) {
                    rowObject.user = matchingUser.fullName;
                } else {
                    rowObject.user = response.user;
                }

                rowObject.submissionDate = response.submissionDate;
                // console.log(response.answers);

                columnsArray.forEach((column) => {
                    let matchingAnswer = response.answers.filter(
                        (a) => a.question === column.key
                    )[0];

                    if (matchingAnswer) {
                        let answerText = formatAnswerText(matchingAnswer);
                        // let answerText = matchingAnswer.answer;

                        rowAnswers.push(answerText);
                    } else {
                        // If there is no answer, need to insert a blank one so the columns line up
                        rowAnswers.push("");
                    }
                });

                // console.log("------------");
                rowObject.answers = rowAnswers;

                rowsArray.push(rowObject);
            });

            setRows(rowsArray);
            setColumns(columnsArray);
        }
    }

    function formatAnswerText(answer) {
        let answerText = answer.answer;
        if (answerText === "") {
            return answerText;
        }

        if (answerText.includes("£$%")) {
            answerText = answerText.replace("£$%", ", ");
        }

        return answerText;
    }

    const handleStatusClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setStatusIsVisible(false);
    };

    const handleChangeTab = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <>
            <Paper square>
                <Tabs
                    value={currentTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChangeTab}
                    centered
                >
                    <Tab label="Survey Results" />
                    <Tab label="" />
                </Tabs>
            </Paper>
            <BackButton />
            <TabPanel value={currentTab} index={0}>
                <SurveyResultsTable
                    rows={rows}
                    columns={columns}
                    anonymous={anonymous}
                />
            </TabPanel>
            {/* <TabPanel value={currentTab} index={1}>
                <SurveyResultsFeed responses={responses} />
            </TabPanel> */}
            <Snackbar
                open={statusIsVisible}
                autoHideDuration={5000}
                onClose={handleStatusClose}
            >
                <Alert onClose={handleStatusClose} severity={statusType}>
                    {statusMessage}
                </Alert>
            </Snackbar>
            <ToolbarWithLogo />
        </>
    );
}
