import {
    CallToAction,
    Content,
    Email,
    Header,
    Paragraph,
    Title,
} from "@rakered/email";

export default function FileLinkEmail(props) {
    return (
        <Email>
            <Content>
                <Header logo="https://firebasestorage.googleapis.com/v0/b/nodiant-e20a8.appspot.com/o/180.png?alt=media&token=9821b2a9-1a70-4f63-a488-96377bb3a234" />
                <Title>Nodiant Online</Title>
                <Paragraph>
                    You have been sent a link to a file on Nodiant Online:
                </Paragraph>
                <Paragraph>{props.filename}</Paragraph>

                <CallToAction href={props.link}>Go To File</CallToAction>
            </Content>
        </Email>
    );
}
