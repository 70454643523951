import React, {
    useEffect,
    useContext,
    useState,
    useCallback,
    useReducer,
} from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// My Files
import { DatabaseContext, UserContext } from "../../context";
import ToolbarWithLogo from "../ToolbarWithLogo";
import SurveyQuestionEdit from "./SurveyQuestionEdit";
import firebase from "../../firebase";
import { makeid, stringToBool } from "../../utility";
import ShareSurveyDialog from "./ShareSurveyDialog";
import ConfirmDialog from "../ConfirmDialog";
import BackButton from "../BackButton";
import ProjectSelectFileDialog from "../projects/ProjectSelectFileDialog";

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        padding: 15,
        paddingBottom: 150,
    },
    paper: {
        padding: 15,
        // backgroundColor: "purple",
    },
    dividerDiv: {
        height: 15,
        width: "100%",
    },
    inputTextField: {
        // marginBottom: 15,
    },
    shareButton: { marginTop: 0, marginLeft: 0, marginBottom: 15 },
    appBar: {
        top: "auto",
        bottom: 65,
        height: 50,
    },
    fabButton: {
        position: "absolute",
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: "0 auto",
    },
    deleteButton: {
        marginBottom: 15,
    },
    grow: {
        flexGrow: 1,
    },
    anonymousSwitch: {
        marginBottom: 5,
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SurveyEditPage(props) {
    const classes = useStyles();
    const { currentUserData } = useContext(UserContext);
    const location = useLocation();
    const history = useHistory();

    const { surveyInfo, groups } = useContext(DatabaseContext);

    const [title, setTitle] = useState("");
    const [introduction, setIntroduction] = useState("");

    const [key, setKey] = useState("");

    const [survey, setSurvey] = useState();
    const [surveyInfoObject, setSurveyInfoObject] = useState();

    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    const [statusType, setStatusType] = useState("error");
    const [statusMessage, setStatusMessage] = useState("");
    const [statusIsVisible, setStatusIsVisible] = useState(false);

    const [shareDialogOpen, setShareDialogOpen] = useState(false);

    const [canShareSurveys, setCanShareSurveys] = useState(false);

    const [deleteConfirmDialogIsOpen, setDeleteConfirmDialogIsOpen] =
        useState(false);

    const [anonymousAnswers, setAnonymousAnswers] = useState(true);

    const [selectExistingFileDialogOpen, setSelectExistingFileDialogOpen] =
        useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const getSurvey = useCallback(
        async (key) => {
            if (!survey) {
                let surveyRef = firebase.database().ref("surveys").child(key);

                console.log("Reading Survey from Firebase");

                surveyRef.on("value", function (snapshot) {
                    let key = snapshot.key;
                    let data = snapshot.val();

                    let questionsArray = [];

                    if (data && data.questions) {
                        let questionKeys = Object.keys(data.questions);
                        let questionValues = Object.values(data.questions);

                        for (let i = 0; i < questionKeys.length; i++) {
                            var optionsArray = [];

                            let questionObject = {
                                key: questionKeys[i],
                                row: questionValues[i].row,
                                questionText: questionValues[i].questionText,
                                answerFormat: questionValues[i].answerFormat,
                                answerRequired: stringToBool(
                                    questionValues[i].answerRequired
                                ),
                                fileType: questionValues[i].fileType,
                                linkKey: questionValues[i].linkKey,
                            };

                            // Get the multiple choice options if present
                            if (questionValues[i].multipleChoiceOptions) {
                                let optionKeys = Object.keys(
                                    questionValues[i].multipleChoiceOptions
                                );
                                let optionValues = Object.values(
                                    questionValues[i].multipleChoiceOptions
                                );

                                for (let j = 0; j < optionKeys.length; j++) {
                                    let optionObject = {
                                        key: optionKeys[j],
                                        index: optionValues[j].index,
                                        text: optionValues[j].text,
                                    };

                                    optionsArray.push(optionObject);
                                }
                            }

                            questionObject.multipleChoiceOptions = optionsArray;
                            questionsArray.push(questionObject);
                        }
                    }

                    let survey = {
                        key: key,
                        questions: questionsArray.sort((a, b) =>
                            a.row > b.row ? 1 : -1
                        ),
                    };

                    // console.log(survey);
                    console.log("Setting Survey");
                    setSurvey(survey);
                });
            } else {
                console.log("Already Read Survey");
            }
        },
        [survey]
    );
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);

        const key = urlParams.get("key");

        let matchingInfo = surveyInfo.filter((s) => s.key === key)[0];

        if (matchingInfo && currentUserData) {
            let hasAccess = false;

            // Check if user created survey

            if (matchingInfo.createdBy === currentUserData.key) {
                hasAccess = true;
            }

            // Check if user was added to survey individually
            let matchingUser = matchingInfo.users.filter(
                (u) => u.key === currentUserData.key
            )[0];

            if (matchingUser) {
                if (matchingUser.canEdit) {
                    hasAccess = true;
                }
            }

            setAnonymousAnswers(matchingInfo.anonymous);

            // Check if user is in a group that was added to the survey
            matchingInfo.groups.forEach((surveyGroup) => {
                let matchingGroup = groups.filter(
                    (g) => g.key === surveyGroup.key
                )[0];
                if (matchingGroup) {
                    if (
                        matchingGroup.users.filter(
                            (u) => u.key === currentUserData.key
                        ).length > 0
                    ) {
                        if (surveyGroup.canEdit) {
                            hasAccess = true;
                        } else {
                        }
                    }
                }
            });

            if (hasAccess) {
                if (currentUserData.permissions.createSurveys) {
                    setCanShareSurveys(true);
                }

                setSurveyInfoObject(matchingInfo);
                setKey(matchingInfo.key);
                setTitle(matchingInfo.title);
                setIntroduction(matchingInfo.intro);
                getSurvey(key);
            }

            if (survey) {
                console.log("Have read survey succesfully");
            }
        }

        // eslint-disable-next-line
    }, [currentUserData, surveyInfo, survey]);

    function handleTextFieldChange(event) {
        let newValue = event.target.value;

        if (event.target.id === "surveyTitle") {
            // Change in firebase
            firebase
                .database()
                .ref("surveyinfo")
                .child(survey.key)
                .child("title")
                .set(newValue);

            setTitle(newValue);
        } else if (event.target.id === "surveyIntro") {
            // Change in firebase
            firebase
                .database()
                .ref("surveyinfo")
                .child(survey.key)
                .child("intro")
                .set(newValue);

            setIntroduction(newValue);
        }
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAddComponentClick = (event) => {
        setAnchorEl(event.target);
    };

    function handleNewExistingFilePressed() {
        setSelectExistingFileDialogOpen(true);
        setAnchorEl(null);
    }

    function handleSelectExistingFileClose(selectedFile) {
        if (selectedFile) {
            let newQuestion = {
                questionText: "",
                answerRequired: "false",
                answerFormat: "File",
                fileType: selectedFile.type,
                linkKey: selectedFile.key,
                row: survey.questions.length,
            };

            survey.questions = [...survey.questions, newQuestion];

            // Write new question to firebase

            let questionRef = firebase
                .database()
                .ref("surveys/" + key + "/questions")
                .push();

            questionRef.set(newQuestion);

            forceUpdate();
            setAnchorEl(null);
        }

        setSelectExistingFileDialogOpen(false);
    }

    function handleAddQuestionClick() {
        let newQuestion = {
            questionText: "",
            answerRequired: "true",
            answerFormat: "Short Text",
            row: survey.questions.length,
        };

        survey.questions = [...survey.questions, newQuestion];

        // Write new question to firebase

        let questionRef = firebase
            .database()
            .ref("surveys/" + key + "/questions")
            .push();

        questionRef.set(newQuestion);

        // Create a placeholder multiple choice option
        let optionKey = makeid(20);
        questionRef
            .child("multipleChoiceOptions")
            .child(optionKey)
            .set({ index: 0, text: "Option 1", key: optionKey });

        forceUpdate();
        setAnchorEl(null);

        // var myQuestions = [
        //     {
        //         question: "First Question",
        //         answerFormat: "ShortText",
        //         answerRequired: "true",
        //     },
        //     {
        //         question: "Second Question",
        //         answerFormat: "ShortText",
        //         answerRequired: "false",
        //     },
        // ];

        // var questionsJSON = {};
        // myQuestions.forEach((myQuestion) => {
        //     let questionRef = firebase
        //         .database()
        //         .ref("surveys/" + key + "/questions")
        //         .push();

        //     questionRef.set(myQuestion);
        //     // questionsJSON[myQuestion.key] = true;
        //     // questionsJSON[title] = myQuestion.title;
        // });

        // console.log(questionsJSON);
    }

    function handleDeleteQuestionClick(question) {
        // console.clear();
        // console.log(question);

        // // Remove Object from Firebase
        // firebase
        //     .database()
        //     .ref("surveys/" + survey.key + "/questions/" + question.key)
        //     .remove();
        // // Remove Object Locally

        // let newQuestions = survey.questions.filter(
        //     (q) => q.key !== question.key
        // );

        // // Re-set row indexes so they are sequential after the delete

        // var row = 0;
        // newQuestions
        //     .sort((a, b) => (a.row > b.row ? 1 : -1))
        //     .forEach((question) => {
        //         question.row = row;

        //         firebase
        //             .database()
        //             .ref("surveys/" + survey.key + "/questions/" + question.key)
        //             .child("row")
        //             .set(row);

        //         row += 1;
        //     });

        // survey.questions = newQuestions;

        // forceUpdate();
        // First make sure the row values for each of the remaining questions is correct
        var index = 0;
        survey.questions
            .filter((c) => c.key !== question.key)
            .forEach((question) => {
                firebase
                    .database()
                    .ref("surveys")
                    .child(key)
                    .child("questions")
                    .child(question.key)
                    .update({ row: index });

                question.row = index;
                index += 1;
            });

        // Then remove the deleted question
        firebase
            .database()
            .ref("surveys")
            .child(key)
            .child("questions")
            .child(question.key)
            .remove();
    }

    function handleQuestionMoveUpPressed(question) {
        if (question.row === 0) {
            return;
        }

        let previousQuestion = survey.questions[question.row - 1];

        let previousQuestionRow = (previousQuestion.row += 1);
        let questionRow = (question.row -= 1);

        firebase
            .database()
            .ref("surveys")
            .child(key)
            .child("questions")
            .child(previousQuestion.key)
            .update({ row: previousQuestionRow });

        firebase
            .database()
            .ref("surveys")
            .child(key)
            .child("questions")
            .child(question.key)
            .update({ row: questionRow });

        setSurvey(null);
    }

    function handleQuestionMoveDownPressed(question) {
        if (question.row === survey.questions.length - 1) {
            return;
        }

        let nextQuestion = survey.questions[question.row + 1];

        let nextQuestionRow = (nextQuestion.row -= 1);
        let questionRow = (question.row += 1);

        firebase
            .database()
            .ref("surveys")
            .child(key)
            .child("questions")
            .child(nextQuestion.key)
            .update({ row: nextQuestionRow });

        firebase
            .database()
            .ref("surveys")
            .child(key)
            .child("questions")
            .child(question.key)
            .update({ row: questionRow });

        setSurvey(null);
    }

    const handleStatusClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setStatusIsVisible(false);
    };

    function handleShareSurveyClicked() {
        setShareDialogOpen(true);
    }

    function handleCloseShareDialog(userCount, groupCount) {
        if (userCount > 0 || groupCount > 0) {
            setStatusMessage(
                "Survey shared with " +
                    userCount +
                    " Users and " +
                    groupCount +
                    " Groups"
            );

            setStatusType("success");
            setStatusIsVisible(true);
        }
        setShareDialogOpen(false);
    }

    function handleDeleteSurveyPressed() {
        setDeleteConfirmDialogIsOpen(true);
    }

    function handleCloseConfirmDeleteDialog(result) {
        if (result === true) {
            // Confirmed that we want to delete the survey
            // Delete survey Info Object
            firebase
                .database()
                .ref("surveyinfo")
                .child(surveyInfoObject.key)
                .remove();

            // Delete Survey Object
            firebase
                .database()
                .ref("surveys")
                .child(surveyInfoObject.key)
                .remove();

            // Delete Responses

            history.replace("/surveys/s");
        }

        setDeleteConfirmDialogIsOpen(false);
    }
    function handleAnonymousAnswersChange() {
        let newValue = !anonymousAnswers;

        firebase
            .database()
            .ref("surveyinfo")
            .child(surveyInfoObject.key)
            .update({ anonymous: newValue });

        setAnonymousAnswers(!newValue);
    }

    return (
        <>
            <BackButton />
            <Container className={classes.pageContainer}>
                <Paper className={classes.paper} variant="outlined">
                    <TextField
                        fullWidth
                        className={classes.inputTextField}
                        value={title}
                        onChange={handleTextFieldChange}
                        id="surveyTitle"
                        label="Survey Title"
                        variant="outlined"
                    />
                    <div className={classes.dividerDiv} />
                    <TextField
                        fullWidth
                        multiline
                        rows={5}
                        className={classes.inputTextField}
                        value={introduction}
                        onChange={handleTextFieldChange}
                        id="surveyIntro"
                        label="Introduction"
                        variant="outlined"
                    />
                </Paper>
                <div className={classes.dividerDiv} />
                {survey &&
                    survey.questions.map((q) => (
                        <React.Fragment key={q.key}>
                            <SurveyQuestionEdit
                                key={q.key}
                                surveyKey={survey.key}
                                question={q}
                                handleDeleteQuestionClick={
                                    handleDeleteQuestionClick
                                }
                                handleQuestionMoveUpPressed={
                                    handleQuestionMoveUpPressed
                                }
                                handleQuestionMoveDownPressed={
                                    handleQuestionMoveDownPressed
                                }
                            />
                            <div className={classes.dividerDiv} />
                        </React.Fragment>
                    ))}
                {canShareSurveys && surveyInfoObject && (
                    <ShareSurveyDialog
                        open={shareDialogOpen}
                        onClose={handleCloseShareDialog}
                        surveyInfo={surveyInfoObject}
                    />
                )}
            </Container>
            <Snackbar
                open={statusIsVisible}
                autoHideDuration={6000}
                onClose={handleStatusClose}
            >
                <Alert onClose={handleStatusClose} severity={statusType}>
                    {statusMessage}
                </Alert>
            </Snackbar>
            {/* <FloatingAddButton onClick={handleAddQuestionClick} /> */}
            <AppBar position="fixed" color="white" className={classes.appBar}>
                <Toolbar>
                    {canShareSurveys && (
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.shareButton}
                            onClick={handleShareSurveyClicked}
                        >
                            Share Survey
                        </Button>
                    )}
                    <Tooltip title="Add">
                        <Fab
                            color="primary"
                            aria-label="add"
                            className={classes.fabButton}
                            onClick={handleAddComponentClick}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                    {/* <Tooltip title="Add Question">
                        <Fab
                            color="primary"
                            aria-label="add"
                            className={classes.fabButton}
                            onClick={handleAddQuestionClick}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip> */}
                    <div className={classes.grow} />
                    <Tooltip title="If this is selected, user names will not appear in the survey results">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={anonymousAnswers}
                                    onChange={handleAnonymousAnswersChange}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Anonymous Answers"
                        />
                    </Tooltip>

                    <Tooltip title="Delete Survey">
                        <IconButton
                            className={classes.deleteButton}
                            edge="end"
                            onClick={handleDeleteSurveyPressed}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            {deleteConfirmDialogIsOpen && (
                <ConfirmDialog
                    open={deleteConfirmDialogIsOpen}
                    onClose={handleCloseConfirmDeleteDialog}
                    title={"Confirm Delete"}
                    message={"Are you sure you want to delete this survey?"}
                />
            )}
            {selectExistingFileDialogOpen && (
                <ProjectSelectFileDialog
                    open={selectExistingFileDialogOpen}
                    onClose={handleSelectExistingFileClose}
                />
            )}
            <Menu
                id="comment-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleAddQuestionClick}>
                    Add Question
                </MenuItem>
                <MenuItem onClick={handleNewExistingFilePressed}>
                    Add Resource from Files
                </MenuItem>
            </Menu>
            <ToolbarWithLogo />
        </>
    );
}
