import React, { useEffect, useContext, useState } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
// My Files
import { UserContext, DatabaseContext } from "../../context";
import ProjectPDFFileComponent from "../projects/Components/File Types/ProjectPDFFileComponent";
import ProjectVideoFileComponent from "../projects/Components/File Types/ProjectVideoFileComponent";
import ProjectAudioFileComponent from "../projects/Components/File Types/ProjectAudioFileComponent";
import ProjectImageFileComponent from "../projects/Components/File Types/ProjectImageFileComponent";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        margin: 0,
    },
    row: {
        padding: 0,
        margin: 0,
    },
    col: {
        padding: 0,
        margin: 0,
    },
    arrowIcon: {
        padding: 0,
    },
    deleteIcon: { padding: 0 },
    addedByText: {
        color: "rgb(180, 180, 180)",
        fontSize: "12px",
        paddingLeft: 15,
    },
}));

export default function SurveyFileComponent(props) {
    const classes = useStyles();
    const { currentUserData } = useContext(UserContext);

    const { files } = useContext(DatabaseContext);

    const [isEditing, setIsEditing] = useState(false);
    const [contentWidth, setContentWidth] = useState();

    const [file, setFile] = useState();

    useEffect(() => {
        if (currentUserData) {
            if (props.question) {
                setIsEditing(props.isEditing);

                let matchingFile = files.filter(
                    (f) => f.key === props.question.linkKey
                )[0];
                if (matchingFile !== null) {
                    setFile(matchingFile);
                } else {
                    console.log("No matching file found");
                }
            }

            let newWidth = window.innerWidth - 500.0;

            if (newWidth > 900) {
                newWidth = 900;
            }

            setContentWidth(newWidth);

            function handleResize() {
                let newWidth = window.innerWidth - 500.0;

                if (newWidth > 900) {
                    newWidth = 900;
                }

                setContentWidth(newWidth);
            }

            window.addEventListener("resize", handleResize);
        }

        // eslint-disable-next-line
    }, [currentUserData]);

    function handleDeletePressed() {
        props.delete(props.question);
    }

    function handleMoveUpPressed() {
        props.moveUp(props.question);
    }

    function handleMoveDownPressed() {
        props.moveDown(props.question);
    }

    return (
        <>
            {isEditing ? (
                <Container className={classes.container}>
                    <Row className={classes.row}>
                        <Col className={classes.col}>
                            {props.question.fileType.includes("video") && (
                                <ProjectVideoFileComponent
                                    file={file}
                                    contentWidth={contentWidth}
                                />
                            )}
                            {props.question.fileType.includes("image") && (
                                <ProjectImageFileComponent
                                    file={file}
                                    contentWidth={contentWidth}
                                />
                            )}
                            {props.question.fileType.includes("audio") && (
                                <ProjectAudioFileComponent
                                    file={file}
                                    contentWidth={contentWidth}
                                />
                            )}
                            {props.question.fileType === "application/pdf" && (
                                <ProjectPDFFileComponent
                                    file={file}
                                    contentWidth={contentWidth}
                                />
                            )}
                        </Col>
                        <Col>
                            <ButtonGroup orientation="vertical">
                                <IconButton
                                    className={classes.arrowIcon}
                                    aria-label="moveUp"
                                    onClick={handleMoveUpPressed}
                                >
                                    <ArrowUpwardIcon />
                                </IconButton>
                                <IconButton
                                    className={classes.arrowIcon}
                                    aria-label="moveDown"
                                    onClick={handleMoveDownPressed}
                                >
                                    <ArrowDownwardIcon />
                                </IconButton>
                            </ButtonGroup>
                            <Tooltip title="Delete">
                                <IconButton
                                    className={classes.deleteIcon}
                                    aria-label="delete"
                                    onClick={handleDeletePressed}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <Container className={classes.container}>
                    <Row className={classes.row}>
                        <Col className={classes.col}>
                            {props.question.fileType.includes("video") && (
                                <ProjectVideoFileComponent
                                    file={file}
                                    contentWidth={contentWidth}
                                />
                            )}
                            {props.question.fileType.includes("image") && (
                                <ProjectImageFileComponent
                                    file={file}
                                    contentWidth={contentWidth}
                                />
                            )}
                            {props.question.fileType.includes("audio") && (
                                <ProjectAudioFileComponent
                                    file={file}
                                    contentWidth={contentWidth}
                                />
                            )}
                            {props.question.fileType === "application/pdf" && (
                                <ProjectPDFFileComponent
                                    file={file}
                                    contentWidth={contentWidth}
                                />
                            )}
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
}
