import React, { useEffect, useContext, useState, useReducer } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import { Col } from "react-bootstrap";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// My Files
import { UserContext } from "../../context";
import { boolToString, makeid, range } from "../../utility";
import "../calendar/Calendar.css";
import firebase from "../../firebase";
import SurveyFileComponent from "./SurveyFileComponent";

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        padding: 0,
    },
    paper: {
        padding: 15,
        // backgroundColor: "purple",
    },
    dividerDiv: {
        height: 15,
        width: "100%",
    },
    divider: {
        marginTop: 20,
        marginBottom: 20,
        width: "100%",
    },
    row: {
        padding: 0,
        margin: 0,
    },
    col: {
        padding: 0,
        margin: 0,
    },
    inputTextField: {
        // marginBottom: 15,
    },
    formControl: {
        minWidth: 120,
    },
    numericAnswerControl: {
        minWidth: 160,
    },
    calendar: {
        maxWidth: 350,
    },
    arrowIcon: {
        padding: 0,
    },
    deleteIcon: {
        padding: 0,
        // position: "absolute",
        // right: 10,
        // left: "auto",
        // color: "red",
    },
    switch: {
        marginTop: 0,
        marginBottom: 0,
    },
}));

export default function SurveyQuestionEdit(props) {
    const classes = useStyles();
    const { currentUserData } = useContext(UserContext);
    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    const [question, setQuestion] = useState({});
    const [questionText, setQuestionText] = useState("");
    const [answerFormat, setAnswerFormat] = useState("Short Text");
    const [answerRequired, setAnswerRequired] = useState(false);

    const [multipleChoiceOptions, setMultipleChoiceOptions] = useState([]);
    const [numberOptions, setNumberOptions] = useState([]);

    useEffect(() => {
        if (currentUserData) {
            setNumberOptions(range(151, 0));

            if (props.question) {
                // console.log(props.question);
                setQuestion(props.question);
                setQuestionText(props.question.questionText);
                setAnswerFormat(props.question.answerFormat);
                setAnswerRequired(props.question.answerRequired);
                setMultipleChoiceOptions(props.question.multipleChoiceOptions);
            }
        }

        // eslint-disable-next-line
    }, [currentUserData]);

    // Convenience function, gets the firebase database reference
    function questionRef() {
        return firebase
            .database()
            .ref("surveys/" + props.surveyKey + "/questions/" + question.key);
    }

    function handleTextFieldChange(event) {
        let newValue = event.target.value;

        if (event.target.id === "questionText") {
            // console.log(question);
            question.questionText = newValue;
            setQuestionText(newValue);

            questionRef().child("questionText").set(newValue);
        }
    }

    function handleOptionChange(event) {
        let matchingOption = multipleChoiceOptions.filter(
            (o) => o.index === parseInt(event.target.id)
        )[0];

        if (matchingOption) {
            let newValue = event.target.value;
            matchingOption.text = newValue;

            questionRef()
                .child("multipleChoiceOptions")
                .child(matchingOption.key)
                .child("text")
                .set(newValue);

            forceUpdate();
        }
    }

    function handleAddOptionPress() {
        let newIndex = multipleChoiceOptions.length;

        let newText = "Option " + (newIndex + 1);

        let newOptionObject = {
            index: newIndex,
            text: newText,
            key: makeid(20),
        };

        let newArray = [...multipleChoiceOptions, newOptionObject];

        question.multipleChoiceOptions = newArray;

        setMultipleChoiceOptions(newArray);

        questionRef()
            .child("multipleChoiceOptions")
            .child(newOptionObject.key)
            .set(newOptionObject);
    }

    const handleDeleteOptionPress = (id) => () => {
        // Remove the deleted option from firebase
        let matchingOption = multipleChoiceOptions.filter(
            (o) => o.index === parseInt(id)
        )[0];

        if (matchingOption) {
            questionRef()
                .child("multipleChoiceOptions")
                .child(matchingOption.key)
                .remove();
        }

        // Update the indexes of the remaining options, so they aren't missing indexes
        let newOptions = multipleChoiceOptions.filter(
            (o) => o.index !== parseInt(id)
        );

        var index = 0;
        newOptions
            .sort((a, b) => (a.index > b.index ? 1 : -1))
            .forEach((option) => {
                option.index = index;
                index += 1;

                // Write the option to firebase
                questionRef()
                    .child("multipleChoiceOptions")
                    .child(option.key)
                    .set(option);
            });

        question.multipleChoiceOptions = newOptions;
        setMultipleChoiceOptions(newOptions);

        forceUpdate();
    };

    function handleAnswerRequiredToggled() {
        let newValue = !answerRequired;
        question.answerRequired = newValue;

        setAnswerRequired(newValue);

        questionRef().child("answerRequired").set(boolToString(newValue));
    }

    function handleChangeAnswerFormat(event) {
        let newValue = event.target.value;

        setAnswerFormat(newValue);
        question.answerFormat = newValue;

        questionRef().child("answerFormat").set(newValue);
    }

    function handleDeleteQuestionPressed() {
        props.handleDeleteQuestionClick(question);
    }

    function handleQuestionMoveUpPressed() {
        props.handleQuestionMoveUpPressed(question);
    }

    function handleQuestionMoveDownPressed() {
        props.handleQuestionMoveDownPressed(question);
    }

    return (
        <Container fluid className={classes.pageContainer}>
            <Paper className={classes.paper} variant="outlined">
                {answerFormat !== "File" && (
                    <>
                        <Row className={classes.row}>
                            <Col className={classes.col}>
                                <TextField
                                    fullWidth
                                    className={classes.inputTextField}
                                    value={questionText}
                                    onChange={handleTextFieldChange}
                                    id="questionText"
                                    label="Question"
                                    variant="outlined"
                                />
                            </Col>
                            <Col className={classes.col}>
                                <ButtonGroup orientation="vertical">
                                    <IconButton
                                        className={classes.arrowIcon}
                                        aria-label="moveUp"
                                        onClick={handleQuestionMoveUpPressed}
                                    >
                                        <ArrowUpwardIcon />
                                    </IconButton>
                                    <IconButton
                                        className={classes.arrowIcon}
                                        aria-label="moveDown"
                                        onClick={handleQuestionMoveDownPressed}
                                    >
                                        <ArrowDownwardIcon />
                                    </IconButton>
                                </ButtonGroup>
                                <Tooltip title="Delete">
                                    <IconButton
                                        className={classes.deleteIcon}
                                        aria-label="delete"
                                        onClick={handleDeleteQuestionPressed}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Col>
                        </Row>
                        <Row>
                            <Divider className={classes.divider} />
                        </Row>
                        <Row className={classes.row}>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Answer Format
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={answerFormat}
                                    onChange={handleChangeAnswerFormat}
                                    label="Answer Format"
                                >
                                    <MenuItem value={"Short Text"}>
                                        Short Text
                                    </MenuItem>
                                    <MenuItem value={"Long Text"}>
                                        Long Text
                                    </MenuItem>
                                    <MenuItem value={"Number"}>Number</MenuItem>
                                    <MenuItem value={"True or False"}>
                                        True or False
                                    </MenuItem>
                                    <MenuItem value={"Checkboxes"}>
                                        Checkboxes
                                    </MenuItem>
                                    <MenuItem value={"Multiple Choice"}>
                                        Multiple Choice
                                    </MenuItem>
                                    <MenuItem value={"Date"}>Date</MenuItem>
                                </Select>
                            </FormControl>
                        </Row>
                        <Row className={classes.row}>
                            <div className={classes.dividerDiv} />
                        </Row>
                    </>
                )}

                {answerFormat === "Short Text" && (
                    <Row className={classes.row}>
                        <TextField
                            fullWidth
                            className={classes.inputTextField}
                            value={""}
                            onChange={handleTextFieldChange}
                            label="Short Text Answer"
                            variant="outlined"
                        />
                    </Row>
                )}

                {answerFormat === "Long Text" && (
                    <Row className={classes.row}>
                        <TextField
                            fullWidth
                            className={classes.inputTextField}
                            value={""}
                            onChange={handleTextFieldChange}
                            label="Long Text Answer"
                            variant="outlined"
                        />
                    </Row>
                )}

                {answerFormat === "Number" && (
                    <Row className={classes.row}>
                        <FormControl className={classes.numericAnswerControl}>
                            <InputLabel id="demo-simple-select-label">
                                Numeric Answer
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={1}
                                // onChange={handleChange}
                            >
                                {numberOptions.map((n) => (
                                    <MenuItem key={n} value={n}>
                                        {n}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Row>
                )}

                {answerFormat === "True or False" && (
                    <Row className={classes.row}>
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label=""
                                name=""
                                value={false}
                                // onChange={handleChange}
                            >
                                <FormControlLabel
                                    value="True"
                                    control={<Radio />}
                                    label="True"
                                />
                                <FormControlLabel
                                    value="False"
                                    control={<Radio />}
                                    label="False"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Row>
                )}
                {answerFormat === "Checkboxes" && (
                    <>
                        <Row className={classes.row}>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label=""
                                    name=""
                                    value={false}
                                    // onChange={handleChange}
                                >
                                    {multipleChoiceOptions
                                        .sort((a, b) =>
                                            a.index > b.index ? 1 : -1
                                        )
                                        .map((option) => (
                                            <Row
                                                className={classes.row}
                                                key={option.key}
                                            >
                                                <FormControlLabel
                                                    value={option.text}
                                                    control={
                                                        <Checkbox disabled />
                                                    }
                                                    label={
                                                        <TextField
                                                            fullWidth
                                                            className={
                                                                classes.inputTextField
                                                            }
                                                            value={option.text}
                                                            onChange={
                                                                handleOptionChange
                                                            }
                                                            id={option.index.toString()}
                                                            // label={option.text}
                                                        />
                                                    }
                                                />
                                                <IconButton
                                                    edge="start"
                                                    color="inherit"
                                                    onClick={handleDeleteOptionPress(
                                                        option.index
                                                    )}
                                                    aria-label="deleteoption"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Row>
                                        ))}
                                </RadioGroup>
                            </FormControl>
                        </Row>
                        <Row className={classes.row}>
                            <Tooltip title="Add Option">
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleAddOptionPress}
                                    aria-label="addoption"
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </Row>
                    </>
                )}

                {answerFormat === "Multiple Choice" && (
                    <>
                        <Row className={classes.row}>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label=""
                                    name=""
                                    value={false}
                                    // onChange={handleChange}
                                >
                                    {multipleChoiceOptions
                                        .sort((a, b) =>
                                            a.index > b.index ? 1 : -1
                                        )
                                        .map((option) => (
                                            <Row
                                                className={classes.row}
                                                key={option.key}
                                            >
                                                <FormControlLabel
                                                    value={option.text}
                                                    control={<Radio />}
                                                    label={
                                                        <TextField
                                                            fullWidth
                                                            className={
                                                                classes.inputTextField
                                                            }
                                                            value={option.text}
                                                            onChange={
                                                                handleOptionChange
                                                            }
                                                            id={option.index.toString()}
                                                            // label={option.text}
                                                        />
                                                    }
                                                />
                                                <IconButton
                                                    edge="start"
                                                    color="inherit"
                                                    onClick={handleDeleteOptionPress(
                                                        option.index
                                                    )}
                                                    aria-label="deleteoption"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Row>
                                        ))}
                                </RadioGroup>
                            </FormControl>
                        </Row>
                        <Row className={classes.row}>
                            <Tooltip title="Add Option">
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleAddOptionPress}
                                    aria-label="addoption"
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </Row>
                    </>
                )}
                {answerFormat === "File" && (
                    <SurveyFileComponent
                        surveyKey={props.surveyKey}
                        isEditing={true}
                        question={props.question}
                        moveUp={handleQuestionMoveUpPressed}
                        moveDown={handleQuestionMoveDownPressed}
                        delete={handleDeleteQuestionPressed}
                    />
                )}

                {answerFormat !== "File" && (
                    <>
                        <Row>
                            <Divider className={classes.divider} />
                        </Row>
                        <Row className={classes.row}>
                            <FormControlLabel
                                className={classes.switch}
                                control={
                                    <Switch
                                        checked={answerRequired}
                                        onChange={handleAnswerRequiredToggled}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Answer Required"
                            />
                            {/* <Tooltip title="Delete Question">
                                <IconButton
                                    className={classes.deleteIcon}
                                    aria-label="delete"
                                    onClick={handleDeleteQuestionPressed}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip> */}
                        </Row>
                    </>
                )}
            </Paper>
        </Container>
    );
}
