import React, { useEffect, useContext, useState } from "react";
import Calendar from "react-calendar";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Checkbox from "@material-ui/core/Checkbox";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// My Files
import { UserContext } from "../../context";
import { range } from "../../utility";
import "../calendar/Calendar.css";
import SurveyFileComponent from "./SurveyFileComponent";

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        padding: 0,
    },
    paper: {
        padding: 15,
        // backgroundColor: "purple",
    },
    dividerDiv: {
        height: 15,
        width: "100%",
    },
    divider: {
        marginTop: 20,
        marginBottom: 20,
        width: "100%",
    },
    row: {
        padding: 0,
        margin: 0,
    },
    inputTextField: {
        // marginBottom: 15,
    },
    formControl: {
        minWidth: 120,
    },
    numericAnswerControl: {
        minWidth: 160,
    },
    calendar: {
        maxWidth: 350,
    },
    deleteIcon: {
        // position: "absolute",
        // right: 10,
        // left: "auto",
        // color: "red",
    },
    switch: {
        marginTop: 0,
        marginBottom: 0,
    },
    requiredIndicator: {
        color: "red",
    },
    requiredText: {
        color: "red",
        paddingTop: 10,
    },
}));

export default function SurveyQuestion(props) {
    const classes = useStyles();
    const { currentUserData } = useContext(UserContext);

    const [question, setQuestion] = useState({});
    const [questionText, setQuestionText] = useState("");
    const [answerFormat, setAnswerFormat] = useState("Short Text");
    const [answerRequired, setAnswerRequired] = useState(false);

    const [multipleChoiceOptions, setMultipleChoiceOptions] = useState([]);
    const [numberOptions, setNumberOptions] = useState([]);

    const [textAnswer, setTextAnswer] = useState("");
    const [numberAnswer, setNumberAnswer] = useState(1);
    const [checkboxAnswers, setCheckboxAnswers] = useState([]);
    const [dateAnswer, setDateAnswer] = useState();

    useEffect(() => {
        if (currentUserData) {
            setNumberOptions(range(151, 0));

            if (props.question) {
                setQuestion(props.question);
                setQuestionText(props.question.questionText);
                setAnswerFormat(props.question.answerFormat);
                setAnswerRequired(props.question.answerRequired);
                setMultipleChoiceOptions(props.question.multipleChoiceOptions);
            }
        }

        // eslint-disable-next-line
    }, [currentUserData]);

    function handleTextFieldChange(event) {
        let newValue = event.target.value;

        if (event.target.id === "textAnswer") {
            // console.log(question);
            setTextAnswer(newValue);
            question.responseTextAnswer = newValue;
        }
    }

    function handleNumberChange(event) {
        let newValue = event.target.value;

        setNumberAnswer(newValue);
        question.responseNumberAnswer = newValue;
    }

    function handleCheckboxChange(event) {
        // setTextAnswer(event.target.value);
        let newValue = event.target.value;

        if (checkboxAnswers.includes(newValue)) {
            // Remove
            let newAnswers = checkboxAnswers.filter((a) => a !== newValue);
            setCheckboxAnswers(newAnswers);
            question.responseCheckboxAnswers = newAnswers;
        } else {
            // Add
            let newAnswers = [...checkboxAnswers, newValue];

            setCheckboxAnswers(newAnswers);
            question.responseCheckboxAnswers = newAnswers;
        }
    }

    function handleMultipleChoiceChange(event) {
        let newValue = event.target.value;
        setTextAnswer(newValue);
        question.responseTextAnswer = newValue;
    }

    function onDateChange(nextDate) {
        setDateAnswer(nextDate);
        question.responseDateAnswer = nextDate;
    }

    function showRequiredIndicators() {
        // Show if answer is required, user has tried submitting once already (props.showRequiredText === true)
        // and the user hasn't answered
        if (answerRequired && props.showRequiredText) {
            if (props.questionHasBeenAnswered(question)) {
                return false;
            } else {
                return true;
            }
            // if (
            //     question.answerFormat === "Short Text" ||
            //     question.answerFormat === "Long Text" ||
            //     question.answerFormat === "True or False" ||
            //     question.answerFormat === "Multiple Choice"
            // ) {
            //     if (
            //         question.responseTextAnswer === undefined ||
            //         question.responseTextAnswer === ""
            //     ) {
            //         return true;
            //     }
            // } else if (question.answerFormat === "Number") {
            //     // Number answer
            //     if (question.responseNumberAnswer === undefined) {
            //         return true;
            //     }
            // } else if (question.answerFormat === "Checkboxes") {
            //     // Multiple text answers
            //     if (question.responseCheckboxAnswers) {
            //         if (
            //             question.responseCheckboxAnswers === undefined ||
            //             question.responseCheckboxAnswers === ""
            //         ) {
            //             return true;
            //         }
            //     }
            // } else if (question.answerFormat === "Date") {
            //     // Date Answer
            //     if (question.responseDateAnswer === undefined) {
            //         return true;
            //     }
            // }
        }

        return false;
    }

    return (
        <Container fluid className={classes.pageContainer}>
            <Paper
                style={
                    showRequiredIndicators()
                        ? { padding: 15, borderColor: "red" }
                        : { padding: 15 }
                }
                variant="outlined"
            >
                <Row className={classes.row}>
                    <h5>{questionText}</h5>
                    {answerRequired && (
                        <h6 className={classes.requiredIndicator}>*</h6>
                    )}
                </Row>

                {answerFormat === "Short Text" && (
                    <Row className={classes.row}>
                        <TextField
                            fullWidth
                            rows={1}
                            className={classes.inputTextField}
                            value={textAnswer}
                            id="textAnswer"
                            onChange={handleTextFieldChange}
                            label="Your Answer"
                        />
                    </Row>
                )}
                {answerFormat === "Long Text" && (
                    <Row className={classes.row}>
                        <TextField
                            fullWidth
                            multiline
                            className={classes.inputTextField}
                            value={textAnswer}
                            id="textAnswer"
                            onChange={handleTextFieldChange}
                            label="Your Answer"
                        />
                    </Row>
                )}
                {answerFormat === "Number" && (
                    <Row className={classes.row}>
                        <FormControl className={classes.numericAnswerControl}>
                            <InputLabel>Your Answer</InputLabel>
                            <Select
                                id="numberAnswer"
                                value={numberAnswer}
                                onChange={handleNumberChange}
                            >
                                {numberOptions.map((n) => (
                                    <MenuItem key={n} value={n}>
                                        {n}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Row>
                )}
                {answerFormat === "True or False" && (
                    <Row className={classes.row}>
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label=""
                                name=""
                                value={textAnswer}
                                onChange={handleMultipleChoiceChange}
                            >
                                <FormControlLabel
                                    value="True"
                                    control={<Radio />}
                                    label="True"
                                />
                                <FormControlLabel
                                    value="False"
                                    control={<Radio />}
                                    label="False"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Row>
                )}
                {answerFormat === "Checkboxes" && (
                    <>
                        <Row className={classes.row}>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label=""
                                    name=""
                                    value={false}
                                    // onChange={handleChange}
                                >
                                    {multipleChoiceOptions
                                        .sort((a, b) =>
                                            a.index > b.index ? 1 : -1
                                        )
                                        .map((option) => (
                                            <Row
                                                className={classes.row}
                                                key={option.key}
                                            >
                                                <FormControlLabel
                                                    value={option.text}
                                                    control={<Checkbox />}
                                                    onChange={
                                                        handleCheckboxChange
                                                    }
                                                    label={option.text}
                                                />
                                            </Row>
                                        ))}
                                </RadioGroup>
                            </FormControl>
                        </Row>
                    </>
                )}
                {answerFormat === "Multiple Choice" && (
                    <>
                        <Row className={classes.row}>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label=""
                                    name=""
                                    value={textAnswer}
                                    onChange={handleMultipleChoiceChange}
                                >
                                    {multipleChoiceOptions
                                        .sort((a, b) =>
                                            a.index > b.index ? 1 : -1
                                        )
                                        .map((option) => (
                                            <Row
                                                className={classes.row}
                                                key={option.key}
                                            >
                                                <FormControlLabel
                                                    value={option.text}
                                                    control={<Radio />}
                                                    label={option.text}
                                                />
                                            </Row>
                                        ))}
                                </RadioGroup>
                            </FormControl>
                        </Row>
                    </>
                )}
                {answerFormat === "File" && (
                    <SurveyFileComponent
                        surveyKey={props.surveyKey}
                        isEditing={false}
                        question={question}
                    />
                )}
                {answerFormat === "Date" && (
                    <Calendar
                        className={classes.calendar}
                        onChange={onDateChange}
                        // onClickDay={onClickDay}
                        // showWeekNumbers
                        defaultView="month"
                        value={dateAnswer}
                        // tileContent={tileContent}
                    />
                )}
                {showRequiredIndicators() && (
                    <Row className={classes.row}>
                        <div className={classes.requiredText}>
                            This is a required question
                        </div>
                    </Row>
                )}
            </Paper>
        </Container>
    );
}
